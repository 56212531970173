

























































































































































































































































































































































.pageContol{
    // 滚动条整体部分
    ::-webkit-scrollbar {
        width:0px;
        border-radius: 0px;
    }
    // 内层轨道 
    ::-webkit-scrollbar-track-piece{
        border-radius: 0px;
        background: white;
    }
    // 滚的条
    ::-webkit-scrollbar-thumb {
        border-radius: 0px;
        background-color: #e7e7e7;
    }
}
// 修改颜色 
.framePage-body{
    background-color: #F5F5F6;
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    .block-item{
        flex-shrink: 0;
        height: 48.7%;
        width: 49.7%;
        background-color: white;
        padding: 1.875rem;
        .empty{
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    // 板块1
    .block-item1{
        padding-left: 1.25rem;
        .title{
            font-size: 1.125rem;
            font-weight: 600;
            color: #333333;
            padding-left: .625rem;
        }
        .echatrs{
            width: 100%;
            height: calc(100% - 1.875rem);
            margin-top: 1.875rem;
            overflow: hidden;
        }
    }
    // 板块2
    .block-item2{
        .top{
            display: flex;
            justify-content: space-between;
            .title{
                font-size: 1.125rem;
                font-weight: 600;
                color: #333333;
            }
            .more{
                font-size: .875rem;
                color: #2878FF;
                cursor: pointer;
            }
        }
        .list{
            margin-top: 10px;
            overflow-y: auto;
            height: calc(100% - 1.875rem);
            .list-item{
                cursor: pointer;
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-bottom: 1px solid #F5F5F5;
                padding: 1rem 0;
                .list-item-left{
                    .list-item-top{
                        display: flex;
                        .title{
                            font-size: .875rem;
                            font-weight: 600;
                            color: #333333;
                        }
                        .state{
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 3.25rem;
                            height: 1.25rem;
                            border-radius: 10px;
                            font-size: .75rem;
                            margin-left: 10px;
                        }
                    }
                    .detail{
                        margin-top: 10px;
                        font-size: .75rem;
                        color: #666666;
                        display: flex;
                        .total{
                            margin-left: 20px;
                        }
                        span{
                            color: #2878FF;
                        }
                    }
                }
            }
        }
    }
    // 板块3
    .block-item3{
        background-color: #F5F5F6;
        padding: 1.875rem 0;
        .title{
            font-size: 1.125rem;
            font-weight: 600;
            color: #333333;
            padding-left: 1.875rem;
        }
        .list{
            display: flex;
            flex-wrap: wrap;
            margin-top: 1.875rem;
            .list-item{
                width: 20%;
                border-right: 1px solid #F5F5F5;
                border-bottom: 1px solid #F5F5F5;
                height: 6.75rem;
                background-color: white;
                flex-shrink: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                .img-wrap{
                    width: 2.625rem;
                    height: 2.625rem;
                    cursor: pointer;
                    img{
                        width: 100%;
                        height: 100%;
                        display: block;
                    }
                }
                .subtitle{
                    font-size: .875rem;
                    font-weight: 600;
                    color: #333333;
                    margin-top: 10px;
                }
            }
        }
    }
    // 板块4
    .block-item4{
        .title{
            font-size: 1.125rem;
            font-weight: 600;
            color: #333333;
        }
        .list{
            overflow-y: auto;
            height: calc(100% - 1.875rem);
            margin-top: 10px;
            .list-item{
                cursor: pointer;
                border-bottom: 1px solid #F5F5F5;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 1.25rem 0;
                .className{
                    font-size: .875rem;
                    color: #333333;
                }
                .date{
                    font-size: .75rem;
                    color: #999999;
                }
            }
        }
    }
}
