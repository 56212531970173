.pageContol ::-webkit-scrollbar {
  width: 0px;
  border-radius: 0px;
}
.pageContol ::-webkit-scrollbar-track-piece {
  border-radius: 0px;
  background: white;
}
.pageContol ::-webkit-scrollbar-thumb {
  border-radius: 0px;
  background-color: #e7e7e7;
}
.framePage-body {
  background-color: #F5F5F6;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
.framePage-body .block-item {
  flex-shrink: 0;
  height: 48.7%;
  width: 49.7%;
  background-color: white;
  padding: 1.875rem;
}
.framePage-body .block-item .empty {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.framePage-body .block-item1 {
  padding-left: 1.25rem;
}
.framePage-body .block-item1 .title {
  font-size: 1.125rem;
  font-weight: 600;
  color: #333333;
  padding-left: .625rem;
}
.framePage-body .block-item1 .echatrs {
  width: 100%;
  height: calc(100% - 1.875rem);
  margin-top: 1.875rem;
  overflow: hidden;
}
.framePage-body .block-item2 .top {
  display: flex;
  justify-content: space-between;
}
.framePage-body .block-item2 .top .title {
  font-size: 1.125rem;
  font-weight: 600;
  color: #333333;
}
.framePage-body .block-item2 .top .more {
  font-size: .875rem;
  color: #2878FF;
  cursor: pointer;
}
.framePage-body .block-item2 .list {
  margin-top: 10px;
  overflow-y: auto;
  height: calc(100% - 1.875rem);
}
.framePage-body .block-item2 .list .list-item {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #F5F5F5;
  padding: 1rem 0;
}
.framePage-body .block-item2 .list .list-item .list-item-left .list-item-top {
  display: flex;
}
.framePage-body .block-item2 .list .list-item .list-item-left .list-item-top .title {
  font-size: .875rem;
  font-weight: 600;
  color: #333333;
}
.framePage-body .block-item2 .list .list-item .list-item-left .list-item-top .state {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3.25rem;
  height: 1.25rem;
  border-radius: 10px;
  font-size: .75rem;
  margin-left: 10px;
}
.framePage-body .block-item2 .list .list-item .list-item-left .detail {
  margin-top: 10px;
  font-size: .75rem;
  color: #666666;
  display: flex;
}
.framePage-body .block-item2 .list .list-item .list-item-left .detail .total {
  margin-left: 20px;
}
.framePage-body .block-item2 .list .list-item .list-item-left .detail span {
  color: #2878FF;
}
.framePage-body .block-item3 {
  background-color: #F5F5F6;
  padding: 1.875rem 0;
}
.framePage-body .block-item3 .title {
  font-size: 1.125rem;
  font-weight: 600;
  color: #333333;
  padding-left: 1.875rem;
}
.framePage-body .block-item3 .list {
  display: flex;
  flex-wrap: wrap;
  margin-top: 1.875rem;
}
.framePage-body .block-item3 .list .list-item {
  width: 20%;
  border-right: 1px solid #F5F5F5;
  border-bottom: 1px solid #F5F5F5;
  height: 6.75rem;
  background-color: white;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.framePage-body .block-item3 .list .list-item .img-wrap {
  width: 2.625rem;
  height: 2.625rem;
  cursor: pointer;
}
.framePage-body .block-item3 .list .list-item .img-wrap img {
  width: 100%;
  height: 100%;
  display: block;
}
.framePage-body .block-item3 .list .list-item .subtitle {
  font-size: .875rem;
  font-weight: 600;
  color: #333333;
  margin-top: 10px;
}
.framePage-body .block-item4 .title {
  font-size: 1.125rem;
  font-weight: 600;
  color: #333333;
}
.framePage-body .block-item4 .list {
  overflow-y: auto;
  height: calc(100% - 1.875rem);
  margin-top: 10px;
}
.framePage-body .block-item4 .list .list-item {
  cursor: pointer;
  border-bottom: 1px solid #F5F5F5;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.25rem 0;
}
.framePage-body .block-item4 .list .list-item .className {
  font-size: .875rem;
  color: #333333;
}
.framePage-body .block-item4 .list .list-item .date {
  font-size: .75rem;
  color: #999999;
}
